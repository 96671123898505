<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div class="uk-width-2-3">
                    <div class="uk-grid">
                        <div>
                            <div class="uk-form-controls uk-width-small">
                                <select class="uk-select" id="form-horizontal-select" v-model="meta.type" @change="(e) => changeMeta(e, 'type')">
                                    <option :value="''">Select type</option>
                                    <option v-for="(type, i) in cv_datas.type" :key="i" :value="type.value">{{type.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div class="uk-form-controls uk-width-small">
                                <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
                                    <option :value="''">Select status</option>
                                    <option v-for="(status, i) in cv_datas.status" :key="i" :value="status.value">{{status.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div class="uk-width">
                                <div class="uk-text-center">
                                    <p>Jumlah CV Terbuat: {{ cv_datas.count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-3">
                    <div class="uk-text-right">
                        <downloadexcel
                        class = "uk-button uk-button-success"
                        :fetch = "fetchData"
                        :fields = "json"
                        type = "csv"
                        name = "CV_Data.xls">
                        Export CV
                        </downloadexcel>&#160;
                        <button class="uk-button uk-button-primary" @click="createCV">Create CV</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
                    <thead>
                        <tr>
                            <th>Created At</th>
                            <th>Identitas</th>
                            <th>Kecamatan</th>
                            <th>Kota</th>
                            <th>Pendidikan</th>
                            <th>Industri</th>
                            <th>Posisi</th>
                            <th>Description</th>
                            <th>Tes Buta Warna</th>
                            <th>Tes Kemampuan Dasar</th>
                            <th>Komentar</th>
                            <th>Input Dari</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="cv_classifications.docs.length>0">
                        <tr v-for="(data, i) in cv_classifications.docs" :key="i">
                            <td>{{getDate(data.created_at)}}</td>
                            <td>
                                {{data.fullname}}<br/>
                                {{data.phone || '-'}}<br/>
                                {{data.email || '-'}}<br/>
                                <a v-if="data.cv_url" :href="data.cv_url.file_url" target="_blank">download CV</a>
                            </td>
                            <td>{{data.sub_district || '-'}}</td>
                            <td>{{data.city || '-'}}</td>
                            <td>{{getDataSelect(data.education, cv_datas.education)}}</td>
                            <td>{{data.industry || '-'}}</td>
                            <td>{{data.position || '-'}}</td>
                            <td>{{data.description || '-'}}</td>
                            <td>{{getDataSelect(data.test_buta_warna, cv_datas.test)}}</td>
                            <td>{{getDataSelect(data.test_kemampuan, cv_datas.test)}}</td>
                            <td>{{data.comment || '-'}}</td>
                            <td>{{getDataSelect(data.type, cv_datas.type)}}</td>

                            <td><label-status :type="'cv'" :status_string="data.status" /></td>
                            <td>
                                <button class="uk-button uk-button-primary" @click="updateCV(data.id)">Edit</button><br/>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="17"/>
                </table>
            </div>
            <pagination
                :total-data="cv_classifications.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LabelStatus from '@/components/globals/LabelStatus';
import formatter from "@/utils/formatter";
import downloadexcel from "vue-json-excel";

export default {
    data() {
        return {
            meta: {
                status: '',
                type: '',
                limit: 100,
                page: 1
            },
            json: {
                'Fullname': 'fullname',
                'Phone': 'phone',
                'Email': 'email',
                'Sub-district': 'sub_district',
                'City': 'city',
                'Education': 'education',
                'Industry': 'industry',
                'Position': 'position',
                'Description': 'description',
            }
        };
    },
    components: {
        Pagination,
        EmptyTable,
        LabelStatus,
        downloadexcel
    },
    computed: {
        ...mapGetters({
            cv_classifications: 'cv_classification/cv_classifications',
            cv_datas: 'cv_classification/cv_datas',
        }),
    },
    watch: {
        meta() {
            this.setCVMeta();
        }
    },
    mounted() {
        this.getCVData();
        this.setCVMeta();
    },
    methods: {
        ...mapActions({
            getCV: 'cv_classification/getCV',
            getCVData: 'cv_classification/getCVData'
        }),
        setCVMeta() {
            this.getCV(this.meta);
        },
        updateCV(cvId) {
            this.$router.push(`/admin/cv-classification/update/${cvId}`);
        },
        createCV() {
            this.$router.push(`/admin/cv-classification/create`);
        },
        getDate(data) {
            return formatter.dateComplete(data);
        },
        getDataSelect(data, option) {
            return formatter.selectData(data, option);
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        fetchData() {
            return this.cv_classifications.docs;
        }
    }
};
</script>
